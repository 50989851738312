import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";

import Logo from "../../img/main-logo-2.webp";
import CITLogoAlt from "../../img/exam/new_logo.webp";
import DMSATLogo2 from "../../img/exam/dmsat_logo2.webp";

import { useLocation } from "react-router-dom";
import { Button } from "@mui/material";
// import LoginIcon from "@mui/icons-material/Login";
// import { AccountCircle, HowToReg } from "@mui/icons-material";

const HeaderCIT = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  // const [anchorElNavUser, setAnchorElNavUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  // const handleOpenNavMenuUser = (event) => {
  //   setAnchorElNavUser(event.currentTarget);
  // };

  // const handleCloseNavMenuUser = () => {
  //   setAnchorElNavUser(null);
  // };

  const { pathname } = useLocation();

  // const pos = pathname.includes("dm-sat") ? "sticky" : "static";
  const pos = "sticky";

  const pages = pathname.includes("dm-sat")
    ? [
        {
          name: "Exam Details",
          link: "#exam-details",
        },
        {
          name: "Scholarship Brackets",
          link: "#scholarship",
        },
      ]
    : [
        {
          name: "Exam Details",
          link: "#exam-details",
        },
        {
          name: "Benefits for all",
          link: "#rewards",
        },
      ];

  return (
    <AppBar position={pos} style={{ background: "#fff" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "left",
            }}
          >
            {pages.map((page, i) => (
              <a
                key={i}
                style={{
                  display: "block",
                  marginLeft: "20px",
                  color: "#000",
                  fontWeight: "500",
                  textTransform: "uppercase",
                  fontSize: "14px",
                }}
                href={page.link}
              >
                {page.name}
              </a>
            ))}
          </Box>

          {/* Center Logo */}
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "center",
              display: { xs: "none", md: "flex" },
            }}
          >
            {!pathname.includes("common-internship-test") && (
              <img
                src={Logo}
                onClick={() => (window.location.href = "/")}
                style={{ cursor: "pointer" }}
                alt="Internship Studio"
                width="133px"
                height="50px"
              />
            )}
            {pathname.includes("common-internship-test") && (
              <img
                src={CITLogoAlt}
                onClick={() =>
                  (window.location.href = "/common-internship-test")
                }
                style={{
                  cursor: "pointer",
                }}
                alt="Internship Studio"
                width="auto"
                height="75px"
              />
            )}
            {pathname.includes("dm-sat") && (
              <img
                src={DMSATLogo2}
                onClick={() => (window.location.href = "/dm-sat")}
                style={{
                  cursor: "pointer",
                  borderLeft: "1px solid #000",
                  paddingLeft: "10px",
                  marginLeft: "10px",
                }}
                alt="Internship Studio"
                width="auto"
                height="50px"
              />
            )}
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: "black" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page, i) => (
                <MenuItem key={i} component="a" href={page.link}>
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
              <MenuItem
                component="a"
                href="https://cit.internshipstudio.com/register"
              >
                <Typography textAlign="center">
                  <strong>Register Now</strong>
                </Typography>
              </MenuItem>
              <MenuItem
                component="a"
                href="https://cit.internshipstudio.com/login"
              >
                <Typography textAlign="center">
                  <strong>Login</strong>
                </Typography>
              </MenuItem>
            </Menu>
          </Box>

          <Box sx={{ mr: 2, display: { xs: "flex", md: "none" }, flexGrow: 1 }}>
            {pathname.includes("common-internship-test") && (
              <img
                src={CITLogoAlt}
                onClick={() =>
                  (window.location.href = "/common-internship-test")
                }
                alt="Internship Studio"
                width="auto"
                height="75px"
              />
            )}
            {pathname.includes("dm-sat") && (
              <img
                src={DMSATLogo2}
                onClick={() => (window.location.href = "/dm-sat")}
                alt="Internship Studio"
                width="auto"
                height="75px"
              />
            )}
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
            <Button
              variant="contained"
              className="primaryBackground"
              onClick={() => {
                if (!pathname.includes("dm-sat")) {
                  window.open(
                    "https://cit.internshipstudio.com/register/",
                    "_blank"
                  );
                } else {
                  window.location.href = "#top";
                }
              }}
            >
              Register Now
            </Button>

            {!pathname.includes("dm-sat") && (
              <Button
                variant="outlined"
                className="primaryText"
                onClick={() =>
                  window.open(
                    "https://cit.internshipstudio.com/login/",
                    "_blank"
                  )
                }
                sx={{
                  ml: 1,
                  mr: 5,
                  borderColor: "#ef740d",
                  "&:hover": {
                    borderColor: "#ef740d",
                  },
                }}
              >
                Login
              </Button>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default HeaderCIT;
